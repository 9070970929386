.linkMore {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #161616;
    &:hover {
        cursor: pointer;
    }
}
