.clients {
    &ContentWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 20px;
    }
    &__block {
        object-fit: scale-down;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 300;
        line-height: 70px;
    }
}

@media (min-width: 1056px) {
    .clients {
        &__block {
            object-fit: scale-down;
            width: calc((100% - 20px) / 2);
        }
    }
}

@media (min-width: 1584px) {
    .clients {
        &__block {
            letter-spacing: 0px;
            width: calc((100% - 40px) / 3);
        }
    }
}
