.blogBlock {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 24px 24px;
    height: 300px;
    // margin: -1px;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
}

.blogBlockContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blogBlockContentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
}

.blogBlock__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
}

.blogBlock__tag {
    padding: 5px 10px;
    background: #027a481a;
    border-radius: 1000px;
}

.blogBlock__title {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    margin-bottom: 30px;
}
