.singleBlog {
    padding: 50px 16px 32px;
}

.singleBlog__content p {
    margin: 0.5rem 0;
}
.singleBlog__content li {
    margin: 0.5rem 0;
    padding: 0 0 0 40px;
}

.singleBlog__content ul {
    padding: 0 0 0 40px;
}

.singleBlog__date {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 32px;
    width: 100%;
}

.singleBlog__title {
    font-size: 32px;
    font-weight: 300px;
    margin-bottom: 32px;
}

@media (min-width: 1056px) {
    .singleBlog {
        padding: 50px 32px 32px;
    }
}
