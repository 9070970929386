.navSlider {
    display: none;
}

@media (min-width: 1056px) {
    .navSlider {
        position: sticky;
        align-self: flex-start;
        top: 50px;
        left: 32px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        &Block {
            display: flex;
        }
        &__link {
            padding: 14px 8px;
            border-left: 4px solid #d9d9d9;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
            color: var(--gray-100, #161616);
            &-active {
                border-left: 4px solid #161616;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 1920px) {
    .navSlider {
        left: 0;
        padding-left: 32px;
    }
}
