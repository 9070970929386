.letters {
    &ContentWrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 1056px) {
    .letters {
        &ContentWrapper {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0;
        }
    }
}
