.blogSlider {
    height: 1200px;
    margin-bottom: 30px;
}

.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination {
    bottom: 0 !important;
    overflow: visible;
}

.swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    text-align: center;
    font-size: 18px !important;
    color: #000 !important;
    opacity: 1 !important;
    background: none !important;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: rgba(0, 0, 0, 0.2) !important;
}

@media (min-width: 600px) {
    .blogSlider {
        height: 900px;
    }
}

@media (min-width: 1056px) {
    .blogSlider {
        height: 600px;
    }
}
