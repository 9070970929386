.mainBlock-blog {
    padding-top: 75px;
    padding-bottom: 50px;
}

.mainBlock__date {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
}

.mainBlock__link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "IBM Plex Sans";
    color: black;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;
    margin-top: 24px;
}

.mainBlock__title-blog {
    margin-bottom: 32px;
}
