.tagsSmall {
    display: flex;
    gap: 24px 24px;
    flex-wrap: wrap;
    margin-top: 30px;
}

.tagsSmall__item {
    padding: 10px 28px;
    border-radius: 1000px;
    background: rgba(2, 122, 72, 0.1);
}
