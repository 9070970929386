.header {
    &__navigation {
        &__link {
            box-sizing: border-box;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            color: #525252;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
            padding: 15px 16px;
            border: 2px solid transparent;
            text-decoration: none;
            &:hover {
                cursor: pointer;
                background: #f4f4f4;
                color: #525252;
            }
            &-active {
                border: 2px solid #161616;
            }
        }
        &__arrowLink {
            transition: all 0.2s ease-out;
            transform: rotate(0deg);
            &-active {
                transform: rotate(180deg);
            }
        }
    }
    &__menu {
        transition: all 0.2s ease-out;
        position: fixed;
        display: none;
        top: 48px;
        height: fit-content;
        overflow: hidden;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
        &-open {
            display: flex;
            flex-direction: row;
        }
        &Wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            height: fit-content;
            gap: 0;
        }
        &__item {
            color: var(--text-text-secondary, #525252);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 112.5% */
            letter-spacing: 0.16px;
            padding: 16px 15px;
            box-sizing: border-box;
            border: 2px solid transparent;
            &:hover {
                cursor: pointer;
                background: #e0e0e0;
            }
            &-active {
                background: #e0e0e0;
                border: 2px solid #161616;
            }
        }
        &__itemLink {
            display: flex;
            align-items: center;
            padding: 15px 16px;
            gap: 8px;
            width: calc(100% / 4);
            height: fit-content;
            color: var(--text-text-secondary, #525252);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 90% */
            letter-spacing: 0.16px;
            text-decoration: none;
            &:hover {
                background: #e0e0e0;
                cursor: pointer;
            }
        }
        &__linksWrapper {
            width: calc(100% / 4);
            display: flex;
            flex-direction: column;
            border-right: 2px solid #b0b0b0;
        }
        &__subMenu {
            display: flex;
            align-items: center;
            padding: 15px 16px;
            text-decoration: none;
            color: var(--text-text-secondary, #525252);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 112.5% */
            letter-spacing: 0.16px;
            &:hover {
                background: #e0e0e0;
            }
        }
        &__subMenusWrapper {
            width: calc(100% / 4 * 3);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            grid-auto-flow: column;
        }
    }
}

@media (min-width: 1920px) {
    .header {
        &__menuWrapper {
            max-width: 1856px;
            margin: 0 auto;
        }
    }
}
