.footer {
    padding: 60px 0 74px;
    background-color: #3b3b3b;
    &LinkWrapper {
        padding: 14px 16px;
        &-active {
            background: #6a6868;
        }
        &-borderBot {
            border-bottom: 1px solid #6a6868;
        }
    }
    &MenuBlock {
        border-bottom: 1px solid #6a6868;
    }
    &SubMenu {
        display: none;
        &-open {
            display: block;
        }
    }
    // &TextWrapper {
    // Внести правки по требованию
    // }
    &__link {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
        text-decoration: none;
        &:hover {
            cursor: pointer;
        }
        &-menu {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
    &__logo {
        width: 100%;
        height: auto;
    }
    &__title {
        color: var(--focus-focus-inverse, #fff);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.16px;
        margin-bottom: 44px;
        padding: 0 16px;
    }
    &LicenseWrapper {
        color: var(--text-text-inverse, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
    }
}

@media (min-width: 1056px) {
    .footer {
        padding: 80px 32px;
        &Content {
            display: flex;
            justify-content: space-between;
        }
        &LinkWrapper {
            padding: 5px 0;
            &-borderBot {
                border: none;
            }
        }
        &LicenseWrapper {
            display: flex;
            gap: 34px;
        }
        &LinkWrapper {
            &-borderBot {
                width: calc((100% - 68px) / 3);
            }
            &-license {
                width: calc((100% - 68px) / 3);
                color: #a8a8a8;
            }
        }
        &Menu {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 20px 34px;
        }
        &MenuBlock {
            border: none;
            width: calc((100% - 68px) / 3);
        }
        &RightWrapper {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        &TextWrapper {
            border-top: 1px solid #c6c6c6;
            border-bottom: 1px solid #c6c6c6;
            padding: 25px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            &Top {
                display: flex;
                justify-content: space-between;
                gap: 34px;
            }
        }
        &SubMenu {
            display: block;
        }
        &__link {
            &-menu {
                font-weight: 600;
            }
            &-subMenu {
                color: #a8a8a8;
            }
        }
        &__logo {
            width: 222px;
        }
        &__title {
            padding: 0;
        }
    }
}

@media (min-width: 1584px) {
    .footer {
        &Menu {
            gap: 60px 34px;
        }
        &TextWrapper {
            padding: 30px 0 80px;
            gap: 50px;
        }
    }
}

@media (min-width: 1920px) {
    .footer {
        &Content {
            max-width: 1856px;
            margin: 0 auto;
        }
    }
}
